import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { AlertasService } from '../alertas/alertas.service';
import { StorageService } from '../storage/storage.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
//import { GeneralObsService } from '../observables/general-obs.service';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {
  private paramsSubject = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtl: NavController,
    private storageService: StorageService,
    private alertasService: AlertasService,
    //private generalObsService: GeneralObsService,
    private firebaseAnalyticsService: FirebaseAnalyticsService
  ) {
  }

  goTo(url, data: any = {}, extras: NavigationExtras = {}) {
    this.firebaseAnalyticsService.setCurrentScreen(url);
    if (Object.keys(data).length !== 0) {
      this.alertasService.presentLoading();
      data = Object.assign({}, data);
      data = this.cleanIn(data);
      data = {
        dt: window.btoa(encodeURIComponent(JSON.stringify(data)))
      }

      //this.storageService.remove("dnav");
      this.storageService.set("dnav", data.dt).then(() => {
        return this.router.navigate([url], extras).finally(() => {
          setTimeout(() => {
            this.alertasService.closePresentLoading();
          }, 400)
        });
      });
      return;
    }
    //extras.queryParams = data;
    return this.router.navigate([url], extras);
  }

  goToRoot(url) {
    this.firebaseAnalyticsService.setCurrentScreen(url);
    return this.navCtl.navigateRoot(url);
  }

  back() {
    this.navCtl.back();
  }

  pop() {
    this.navCtl.pop();
  }

  async getParams() {
    let paramsAtob;
    /* if (this.route.snapshot.queryParamMap["params"]['dt']) {
      paramsAtob = JSON.parse(decodeURIComponent(atob(this.route.snapshot.queryParamMap["params"]['dt'])))
    } */
    let params = {};
    let dnav = await this.storageService.get("dnav");
    if (dnav) {
      paramsAtob = JSON.parse(decodeURIComponent(atob(dnav)))
      params = Object.assign({}, paramsAtob);
    }
    return this.cleanOut(params);
  }

  getRouterNavidated() {
    return this.router.navigated;
  }

  getCurrentUrl() {
    return this.router.url;
  }

  getParamsSubs(): Subject<any> {
    this.route.queryParams.subscribe((params) => {
      if (params['dt']) {
        params = JSON.parse(decodeURIComponent(atob(params['dt'])))
      }
      params = Object.assign({}, params);
      this.paramsSubject.next(this.cleanOut(params));
    });
		return this.paramsSubject;
  }

  private cleanIn(obj) {
    var objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
        var keyValOut = obj[key];
        if (keyValOut instanceof Object) {
          obj[key] = JSON.stringify(obj[key]);
          obj[key] = this.cleanIn(obj[key]);
        }
    })
    return obj;
  }

  private cleanOut(obj) {
    var objKeys = Object.keys(obj);
    objKeys.forEach((key) => {
      var keyValOut = obj[key];
      if (typeof keyValOut == "string" && (keyValOut.charAt(0) == "{" || keyValOut.charAt(0) == "[")) {
        obj[key] = JSON.parse(obj[key]);
        obj[key] = this.cleanOut(obj[key]);
      }
    })
    return obj;
  }

}
