import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { Roles } from 'src/app/enums/Roles';
import { AutenticacionService } from 'src/app/servicios/autenticacion/autenticacion.service';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';
import { UsuarioService } from 'src/app/servicios/singleton/usuario.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit{

  public Roles = Roles;
  public env = environment;
  lang: string;

  constructor(
    private navegacionService: NavegacionService,
    public usuService: UsuarioService,
    private autenticacionService: AutenticacionService,
    public platform: Platform,
  ) {

  }

  ngOnInit() {
    this.lang = window.navigator.language;
  }

  onAbrirBateriaAR() {
    Browser.open({url: "https://sotysolar.es/bateria-ar"})
  }

  onIr(url) {
    this.navegacionService.goTo(url);
  }

  logout() {
    this.autenticacionService.logout();
  }



}
