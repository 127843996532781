import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { format } from 'date-fns';
import { Cliente } from 'src/app/modelos/cliente.model';
import { environment } from 'src/environments/environment';
import { AlertasService } from '../alertas/alertas.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class ClienteService{
  public clientes: Array<Cliente> = [];
  
  constructor(
    private storageService: StorageService,
    public platform: Platform,
  ) {
  }

  getTotalXEstado(estado) {
    return this.clientes.reduce((n, c) => c.estadoslug == estado ? n + 1 : n, 0);
  }
  
  getTotalAgendarVisita() {
    return this.clientes.reduce((n, c) => c.esAgendarVisita() ? n + 1 : n, 0);
  }
  
  getTotalInformeVisita() {
    return this.clientes.reduce((n, c) => c.esInformeVisita() ? n + 1 : n, 0);
  }
  
  getTotalAgendarInstalacion() {
    return this.clientes.reduce((n, c) => c.esAgendarInstalacion() ? n + 1 : n, 0);
  }
  
  getTotalInformeInstalacion() {
    return this.clientes.reduce((n, c) => c.esInformeInstalacion() ? n + 1 : n, 0);
  }
  
  getTotalIncidencias() {
    return this.clientes.reduce((n, c) => c.esIncidencia() ? n + 1 : n, 0);
  }
  
  getTotalesIncidenciasInforme() {
    return this.clientes.reduce((n, c) => c.esIncidenciaInforme() ? n + 1 : n, 0);
  }
  
  getTotalesIncidenciasAgendar() {
    return this.clientes.reduce((n, c) => c.esIncidenciaAgendar() ? n + 1 : n, 0);
  }
  
  getTotalLegalizacion() {
    return this.clientes.reduce((n, c) => c.btnLegalizacion() ? n + 1 : n, 0);
  }

  changeClientes(clientes) {
    this.storageService.remove(environment.cli_storage)
    this.clientes = clientes.map((cliente: Cliente) => new Cliente(cliente)).sort((a: any, b: any) => {
      return (!b.getFechaEstado()) ? -1 : new Date(b.getFechaEstado()).valueOf() - new Date(a.getFechaEstado()).valueOf()
    });
    this.storageService.set(environment.cli_storage, this.clientes)
  }

  async setClientesFormTemp(form, clienteId, data) {
    let clientesFormVisitaTemp = await this.storageService.get(form);
    if (!clientesFormVisitaTemp) clientesFormVisitaTemp = {};
    clientesFormVisitaTemp[clienteId] = data;
    this.storageService.set(form, clientesFormVisitaTemp)
  }

  async getClientesFormTemp(form, clienteId) {
    let clientesFormVisitaTemp = await this.storageService.get(form);
    if (!clientesFormVisitaTemp || !clientesFormVisitaTemp[clienteId]) return;
    return clientesFormVisitaTemp[clienteId];
  }
  
  async deleteClientesFormTemp(form, clienteId) {
    let clientesFormVisitaTemp = await this.storageService.get(form);
    if (!clientesFormVisitaTemp || !clientesFormVisitaTemp[clienteId]) return;
    delete clientesFormVisitaTemp[clienteId];
    this.storageService.set(form, clientesFormVisitaTemp)
    return true;
  }

  openLocationGoogle(toLat, toLong) {
    let destination = String(toLat).replace(",", ".") + ', ' + String(toLong).replace(",", ".");
    if(this.platform.is('ios')){
      window.open('maps://?q=' + destination, '_system');
    } else {
      window.open("https://www.google.com/maps/search/?api=1&query="+destination, '_system')
    }
  }

}
